import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

const LogoNav = ({ textAlt }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "COVER.jpg" }) {
        childImageSharp {
          fixed(width: 130, height: 38, trim: 4) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const textAlternative = textAlt ? textAlt : "INICIO";

  const imagFile = data.file;
  return (
    <>
      {data.file && (
        <Image fixed={imagFile.childImageSharp.fixed} alt={textAlternative} />
      )}
    </>
  );
};

export default LogoNav;
